export default {
  413: 'Error 413: Request Entity Too Large',

  'System error': 'Произошла системная ошибка',
  'logout': 'Вы вышли из системы',
  'login' : 'Для начала войдите в систему',
  'Access denied': 'Не достаточно прав доступа',

  'User registered': 'Спасибо за регистрацию. Следуйте инструкциям, отправленым на указанную почту',
  'User not found': 'Пользователя с таким именем и паролем не существует или заблокирован',
  'Invalid username': 'Некорректный логин',
  'Invalid lastname': 'Некорректная фамилия',
  'Invalid firstname': 'Некорректное имя',
  'Invalid middlename': 'Некорректное отчество',
  'Username already exists': 'Логин уже занят',
  'Phone already exists': 'Телефон уже занят',
  'Email already exists': 'Email уже занят',
  'Token not found': 'Не указан токен',
  'Token incorrect': 'Не верный токен',
  'Password reset request success': 'Проверьте вашу почту для дальнейших инструкций',
  'Password reset request error': 'Ошибка сброса пароля',
  'Password reset success': 'Пароль изменен',
  'Password reset error': 'Ошибка смены пароля',

  'User created': 'Пользователь добавлен. На указанную почту отправлены инструкции',
  'User create error': 'Ошибка добавления пользователя',
  'User updated': 'Пользователь обновлен',
  'User update error': 'Ошибка обновления пользователя',
  'User removed': 'Пользователь удален',
  'User remove error': 'Ошибка удаления пользователя',
  'Error remove related user': 'Нельзя удалить пользователя, т.к. с ним связаны данные в других таблицах',

  'Row not found': 'Запись не найдена',
  'Row created': 'Запись добавлена',
  'Row create error': 'Ошибка добавления записи',
  'Row updated': 'Запись обновлена',
  'Row update error': 'Ошибка обновления записи',
  'Files uploaded': 'Файлы загружены',
  'File upload error': 'Ошибка загрузки файлов',
  'Row removed': 'Запись удалена',
  'Row remove error': 'Ошибка удаления записи',
  'Error remove related row': 'Нельзя удалить запись, т.к. она связана с данными в других таблицах',
  'Export error': 'Ошибка экспорта записей',
  'Row imported': 'Записи импортированы',
  'Row import error': 'Ошибка импорта записей',

  'Setting update success': 'Настройки сохранены',
  'Setting update error': 'Ошибка сохранения настроек',

  'Profile colors set': 'Цвета для профиля установлены',

  'Opening type hinges set': 'Петли для типа открывания установлены',

  'Validate error': 'Некоторые поля заполнены не корректно',
  'Invalid client name or phone': 'Для указанного телефона уже зарегистрировано другое имя клиента.',
  'Calculate created': 'Расчет создан',
  'Order created': 'Заказ создан',
  'Order created error': 'Ошибка создания заказа',

  'File not found': 'Файл не найден',

  valid: {
    fieldsNotCorrect: 'Некоторые поля заполнены не корректно.',
    required: 'Обязательное поле',
    username: 'От 2 символов, только латиница, цифры и -_',
    name: 'От 2 символов, только кириллица',
    phone: 'Не корректный телефон',
    domain: 'Не корректный домен',
    positiveNumber: 'Число должно быть больше нуля',
  },

  unknownError: 'Что-то пошло не так. Попробуйте позже.'
}
