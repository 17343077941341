<template>
  <PageLoader />
  <Header />
  <Sidebar />

  <div class="content">
    <div class="content__inner">
      <router-view></router-view>
    </div>
  </div>

  <div v-if="isFadeVisible==true" class="fade show"></div>
</template>

<script>
import messages from '@/lib/messages';
import PageLoader from '@/components/loaders/PageLoader';
import Header from '@/layouts/Header';
import Sidebar from '@/layouts/Sidebar';

export default {
  components: {
    PageLoader,
    Header,
    Sidebar,
  },

  computed: {
    notify() {
      return this.$store.getters.notify;
    },
    isFadeVisible() {
      return this.$store.getters.isFadeVisible;
    },
  },

  watch: {
    notify(obj) {
      this.$notify({ type: obj.type, text: messages[obj.message] || messages.unknownError });
    },
    isFadeVisible(value) {
      if (value === true) document.body.classList.add('fade-show');
      else document.body.classList.remove('fade-show');
    }
  },
};
</script>
