<template>
  <PageLoader />
  <div class="page-center">
    <router-view></router-view>
  </div>
</template>

<script>
import messages from '@/lib/messages';
import PageLoader from '@/components/loaders/PageLoader';

export default {
  components: {
    PageLoader,
  },

  computed: {
    notify() {
      return this.$store.getters.notify;
    },
  },

  watch: {
    notify(obj) {
      this.$notify({ type: obj.type, text: messages[obj.message] || 'Что-то пошло не так' });
    },
  },
};
</script>
