import api from './api';
import TokenService from './token.service';

class AuthService {
  /*register(user) {
    return api
      .post('/auth/register', {
        username: user.username,
        lastname: user.lastname,
        firstname: user.firstname,
        middlename: user.middlename,
        phone: user.phone,
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }*/

  login(user) {
    return api
      .post('/auth/login', {
        username: user.username,
        password: user.password,
      })
      .then((res) => {
        if (res.data.data && res.data.data.accessToken) {
          TokenService.setUser(res.data.data);
        }

        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  logout() {
    api.delete('/auth/refresh-token');
    TokenService.removeUser();
  }

  verifyEmail(data) {
    return api
      .get('/auth/verify-email?token='+data.token)
      .then((res) => {
        if (res.data.data && res.data.data.accessToken) {
          TokenService.setUser(res.data.data);
        }

        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  requestPasswordReset(data) {
    return api
      .post('/auth/request-password-reset', {
        email: data.email,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  passwordReset(data) {
    return api
      .post('/auth/password-reset?token='+data.token, {
        password: data.password,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
}

export default new AuthService();
