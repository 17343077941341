import 'v-calendar/dist/style.css';
import '@/assets/scss/style.scss';
import { createApp } from 'vue';
//import moment from 'moment';
import Inputmask from 'inputmask';
import Notifications from '@kyvg/vue3-notification';
import VCalendar from 'v-calendar';
import FloatingVue from 'floating-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);

const app = createApp(App);

app.use(Notifications);
app.use(VCalendar, {
  locales: {
    'ru-RU': {
      mode: 'date',
      firstDayOfWeek: 2,
      masks: {
        input: 'DD.MM.YYYY',
        data: 'DD.MM.YYYY',
        weekdays: 'WW',
      },
    },
  },
});
app.use(FloatingVue);
app.use(router);
app.use(store);
app.mount('#app');

// inputmask
Inputmask.extendDefaults({
  showMaskOnHover: false,
});
Inputmask.extendAliases({
  phone: {
    mask: '+\\9\\98 (99) 999-99-99',
    placeholder: '_',
    onBeforeMask: function (value) {
      value = value.replace(/[^\d;]/g,'');
      if (value.length == 12 && value.substr(0,3) == 998) value = value.substr(3);
      return value;
    },
  },
  date: {
    alias: 'datetime',
    inputFormat: 'dd.mm.yyyy',
    placeholder: 'дд.мм.гггг',
  },
  integer: {
    alias: 'numeric',
    showMaskOnFocus: false,
    rightAlign: false,
  },
});
app.directive('inputmask', {
  beforeMount(el, binding) {
    function triggerInput() {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      this.dispatchEvent(event);
    }
    Inputmask(binding.value).mask(el);
    el.addEventListener('paste', triggerInput);
    el.addEventListener('cut', triggerInput);
  },
});

// Преобразование timestamp в дату и время
//app.config.globalProperties.$timeFormat = (timestamp, format) => {
  //return (timestamp && format) ? moment(timestamp*1000).utcOffset(3).format(format) : '';
  //return (timestamp && format) ? moment(timestamp*1000).format(format) : '';
//};

// Проверка разрешений
function can(permissions) {
  const userPermissions = store.state.auth.user.permissions;
  let can = false;
  for (let i = 0; i < permissions.length; i++) {
    if (userPermissions.indexOf(permissions[i]) > -1) {
      can = true;
      break;
    }
  }
  return can;
}
app.directive('can', (el, binding, vnode) => vnode.el.hidden = !can(binding.value));
app.config.globalProperties.$can = permissions => can(permissions);
app.provide('can', app.config.globalProperties.$can);
