<template>
  <div class="sidebar" :class="{'toggled':sidebarToggled}">
    <Menu v-if="currentUser">
      <div class="user dropdown" style="top:0">
        <MenuButton class="user__info" as="div">
          <img class="user__avatar" src="@/assets/images/avatar-default.png">
          <div class="user__desc">
            <div class="user__name">{{currentUser.username}}</div>
            <div class="user__desc">{{getRoleDesciption(currentUser.role)}}</div>
          </div>
        </MenuButton>
        <MenuItems class="dropdown-menu show">
          <MenuItem class="dropdown-item"><router-link :to="'/users/'+currentUser.username">Профиль</router-link></MenuItem>
          <MenuItem class="dropdown-item"><a href="javascript:void(0)" @click="logout()">Выход</a></MenuItem>
        </MenuItems>
      </div>
    </Menu>

    <div class="navigation">
      <template v-for="(item1, index1) in menu">
        <div v-if="item1.allow" class="navigation__item" :class="{'open':item1.open,'active':typeof item1.url!='undefined'&&route.path==item1.url}" :key="index1">
          <router-link v-if="item1.type=='link'" class="navigation__item-link" :to="item1.url" ><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</router-link>
          <a v-else-if="item1.type=='external-link'" class="navigation__item-link" :href="item1.url" target="_blank"><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</a>
          <template v-if="item1.items">
            <div class="navigation__item-link" @click="toggleSubmenu(index1)"><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</div>
            <div class="navigation__sub">
              <template v-for="(item2, index2) in item1.items">
                <div v-if="item2.allow&&item2.type=='link'" class="navigation__item" :class="{'active':typeof item2.url!='undefined'&&route.path==item2.url}" :key="index2">
                  <router-link class="navigation__item-link" :to="item2.url">{{item2.title}}</router-link>
                </div>
                <div v-if="item2.allow&&item2.type=='section'" class="navigation__section" :key="index2">
                  <template v-for="(item3, index3) in item2.items">
                    <div v-if="item3.allow" class="navigation__item" :class="{'active':typeof item3.url!='undefined'&&route.path==item3.url}" :key="index3">
                      <router-link v-if="item3.type=='link'" class="navigation__item-link" :to="item3.url">{{item3.title}}</router-link>
                      <div v-if="item3.type=='title'" class="navigation__item-title">{{item3.title}}</div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from '@headlessui/vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { dictRole } from '@/lib/dicts.js';

const router = useRouter();
const route = useRoute();
const store = useStore();
const can = inject('can');

const menu = ref([
  { type: 'external-link', title: 'Калькулятор', icon: 'zmdi-keyboard', url: '/calculator', allow: true },
  { type: 'link', title: 'Заказы', icon: 'zmdi-cloud', url: '/orders', allow: can(['apiOrdersL']) },
  { type: 'link', title: 'Клиенты', icon: 'zmdi-accounts', url: '/clients', allow: can(['apiClientsL']) },
  { type: 'submenu', title: 'Справочники', icon: 'zmdi-format-list-bulleted', allow: can(['apiProfilesL', 'apiProfileColorsL', 'apiGlassColorsL', 'apiAddServicesGlassL']), items: [
    { type: 'link', title: 'Профили', url: '/profiles', allow: can(['apiProfilesL']) },
    { type: 'link', title: 'Цвета профиля', url: '/profile-colors', allow: can(['apiProfileColorsL']) },
    { type: 'link', title: 'Цвета стекла', url: '/glass-colors', allow: can(['apiGlassColorsL']) },
    { type: 'link', title: 'Дополнительные услуги', url: '/add-services-glass', allow: can(['apiAddServicesGlassL']) },
    { type: 'link', title: 'Услуги сборки', url: '/assembly-services', allow: can(['apiAssemblyServicesL']) },
    { type: 'link', title: 'Типы открывания', url: '/opening-types', allow: can(['apiOpeningTypesL']) },
  ] },
  { type: 'link', title: 'Пользователи', icon: 'zmdi-accounts', url: '/users', allow: can(['apiUsersL']) },
  { type: 'submenu', title: 'Настройки', icon: 'zmdi-settings', allow: can(['apiSettingsL']), items: [
    { type: 'link', title: 'Калькулятор', url: '/settings/calculator', allow: can(['apiSettingsL']) },
    { type: 'link', title: 'Телеграм', url: '/settings/telegram', allow: can(['apiSettingsL']) },
  ] },
]);

const sidebarToggled = computed(() => store.getters.sidebarToggled);
const currentUser = computed(() => store.state.auth.user);

const logout = () => {
  store.dispatch('auth/logout')
    .then(() => router.push({ name: 'login' }));
};

const toggleSubmenu = (index) => {
  menu.value[index].open = !menu.value[index].open;
};

const getRoleDesciption = (role) => {
  return dictRole[role];
};
</script>
