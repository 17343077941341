<template>
  <div class="page-loader" v-show="pageLoader">
    <Spinner />
  </div>
</template>


<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Spinner from '@/components/loaders/Spinner.vue';

const store = useStore();

const pageLoader = computed(() => store.state.pageLoader);
</script>
