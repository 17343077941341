// Получение GET-параметров из адреса
export function getUriParams() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = decodeURIComponent(value);
  });
  return vars;
}

// Генерация URL-параметров из объекта
export function httpBuildQuery(formdata, numericPrefix, argSeparator) {
  var key, useVal, useKey, i = 0, tmpArr = [];

  if (!argSeparator) {
    argSeparator = '&';
  }

  for (key in formdata) {
    useKey = escape(key);
    useVal = escape((formdata[key].toString()));
    useVal = useVal.replace(/%20/g, '+');

    if (numericPrefix && !isNaN(key)) {
      useKey = numericPrefix + i;
    }
    tmpArr[i] = useKey + '=' + useVal;
    i++;
  }

  return tmpArr.join(argSeparator);
}

export function numberFormat(e,t,a,r){var s,l;return isNaN(t=Math.abs(t))&&(t=2),null==a&&(a=","),null==r&&(r="."),(l=(s=parseInt(e=(+e||0).toFixed(t))+"").length)>3?l%=3:l=0,(l?s.substr(0,l)+r:"")+s.substr(l).replace(/(\d{3})(?=\d)/g,"$1"+r)+(t?a+Math.abs(e-s).toFixed(t).replace(/-/,0).slice(2):"")}

// Перемешивание массива
export function shuffle(arr) {
  for (let i = arr.length-1; i > 0; i--) {
    let j = Math.floor(Math.random()*(i+1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}

// Отправка размера iframe родителю
export function resize() {
  let height = document.getElementById('app').offsetHeight;
  if (isNaN(height)) height = 0;
  if (window.parent.length != 0) {
    window.parent.postMessage({ event: 'resize', height: parseInt(height) }, '*');
  }
}

function decimalAdjust(t,n,a){return void 0===a||0==+a?Math[t](n):(n=+n,a=+a,isNaN(n)||"number"!=typeof a||a%1!=0?NaN:(n=n.toString().split("e"),+((n=(n=Math[t](+(n[0]+"e"+(n[1]?+n[1]-a:-a)))).toString().split("e"))[0]+"e"+(n[1]?+n[1]+a:a))))}Math.round10||(Math.round10=function(t,n){return decimalAdjust("round",t,n)});
