<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
  <notifications position="bottom right" />
</template>

<script>
import EventBus from '@/common/EventBus';
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';
import CalculatorLayout from '@/layouts/CalculatorLayout';

export default {
  components: {
    EmptyLayout,
    MainLayout,
    CalculatorLayout,
  },

  mounted() {
    EventBus.on('logout', () => {
      this.logOut();
    });
  },

  beforeUnmount() {
    EventBus.remove('logout');
  },

  computed: {
    layout() {
      return this.$route.meta.layout || 'empty';
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    }
  },
}
</script>
