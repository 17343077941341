<template>
  <header class="header">
    <div class="navigation-toggler" :class="{'toggled':sidebarToggled}" @click="store.dispatch('sidebarToggled');">
      <div class="navigation-toggler__inner"><i class="navigation-toggler__line"></i><i class="navigation-toggler__line"></i><i class="navigation-toggler__line"></i></div>
    </div>

    <div class="header__logo">
      <span class="header__logo-text">CRM</span>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const sidebarToggled = computed(() => store.getters.sidebarToggled);
</script>
