import axiosInstance from './api';
import TokenService from './token.service';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer '+token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/auth/login' && err.response) {
        // Access Token просрочен
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const res = await axiosInstance.post('/auth/refresh-token');

            const { accessToken } = res.data.data;

            store.dispatch('auth/refreshToken', accessToken);
            TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);
          } catch (_err) {
            store.dispatch('auth/logout');
            return Promise.reject(_err);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
